const API_PROD = 'https://api.fotofans.nl'
const API_QA = 'https://api-qa.fotofans.nl'
const API_DEV = 'https://api.fotofans.local'

export default function () {
  // return API_PROD
  return API_QA
  const hostname = window.location.hostname
  if (/localhost/.test(hostname)) return API_DEV
  if (/www-qa\.fotofans\.nl/.test(hostname)) return API_QA
  return API_PROD
}
